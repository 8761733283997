<template>
  <footer>
    <div class="footer">
      <article class="social-links">
        <a href="https://www.facebook.com/DJRedmo" target="_blank">
          <img src="../assets/imgs/brands/facebook.webp" alt="facebook" />
        </a>
        <a href="https://www.youtube.com/watch?v=zhjB52Sfb2c" target="_blank">
          <img src="../assets/imgs/brands/youtube.webp" alt="youtube" />
        </a>
        <a
          href="https://open.spotify.com/artist/5ekfkidmB0SttA9xBviKIQ?si=h6HNf4WgTnGequFaIeM3nQ&nd=1"
          target="_blank"
        >
          <img src="../assets/imgs/brands/spotify.webp" alt="spotify"
        /></a>
        <a href="https://www.instagram.com/djredmo/" target="_blank">
          <img src="../assets/imgs/brands/instagram.webp" alt="instagram" />
        </a>
        <a href="https://soundcloud.com/djredmo" target="_blank">
          <img src="../assets/imgs/brands/soundcloud.webp" alt="soundcloud" />
        </a>
        <a href="https://www.tiktok.com/@djredmo?lang=en" target="_blank">
          <img
            src="../assets/imgs/brands/tiktok.png"
            alt="tiktok"
            style="padding: 0.5rem"
          />
        </a>
      </article>
      <form action="https://formsubmit.co/djredmo@gmail.com" method="POST">
        <h3>{{ $t("promolist") }}</h3>
        <input
          type="email"
          name="email"
          placeholder="youremail@email.com"
          size="30"
          required
        />
        <label for="">
          <input type="submit" value="JOIN ME" />
        </label>
      </form>
      <h5 class="copy-rights">&copy; DJ REDMO</h5>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
