<template>
  <div class="presskit">
    <div class="presskit-side">
      <h2>{{ $t("presskit") }}</h2>
      <div class="presskit-download">
        <a
          href="https://www.dropbox.com/sh/gl8hu7crighbwhq/AADS_Cq57RvoPOMHgxbY5QsGa?dl=0"
          target="_blank"
        >
          {{ $t("download") }}
        </a>
      </div>
      <div class="white-pass"></div>
      <div class="presskit-content">
        <h4>{{ $t("content") }}</h4>
        <ul>
          <li>{{ $t("pstudio") }}</li>
          <li>{{ $t("pgigs") }}</li>
          <li>{{ $t("pfestivals") }}</li>
          <li>{{ $t("dvideos") }}</li>
          <li>{{ $t("logo") }}</li>
          <li>{{ $t("music") }}</li>
          <li>{{ $t("nreleases") }}</li>
        </ul>
      </div>
    </div>
    <div class="presskit-imgs">
      <img src="../assets/gifs/drop31.gif" alt="" />
      <img src="../assets/imgs/RedmoG.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>
