<template>
  <nav @click="closeMenu">
    <ul>
      <li>
        <router-link @click.native="closeMenu" to="/">
          {{ $t("home") }}
        </router-link>
      </li>
      <li>
        <router-link @click.native="closeMenu" to="/presskit">
          {{ $t("presskit") }}
        </router-link>
      </li>
      <li>
        <router-link @click.native="closeMenu" to="/contact">
          {{ $t("contact") }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    closeMenu() {
      this.$emit("closeMenu");
    },
  },
};
</script>
