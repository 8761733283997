import Vue from "vue";
import Router from "vue-router";
import homePage from "../pages/home-page.vue";
import presskit from "../pages/presskit-page.vue";
import contact from "../pages/contact-page.vue";
// import component from "./vue-instagram.vue";

// const plugin = {
//   install: (Vue) => {
//     Vue.component(component.name, component);
//   },
// };

// component.install = plugin.install;

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "home",
    component: homePage,
  },
  {
    path: "/presskit",
    name: "presskit",
    component: presskit,
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

// export default component;
export default router;
