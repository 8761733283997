import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    info: `Redmo is a prominent Dj & Producer that has grown throughout the Israeli
    nightlife, With extreme energy and style you have never seen before.&nbsp
    Within a few years Redmo managed to play at the most of the biggest and
    popular clubs and parties at Israel such as: Forum, Selena, Shalvata,
    Madison and the Desert.&nbsp In addition Redmo played at special events with
    over 30k people.&nbsp It's hard to miss Redmo's unique visuals & style when
    he enter the stage.&nbsp It's hard to miss Redmo's unique visuals & style In
    his sets Redmo combines original music, special edits and perform high
    level techniques.&nbsp It's hard to miss Redmo's unique visuals & style Redmo
    is the next promise at the electronic dance scene, As a child who grew
    up on music and achieved great success, He is determined to bring his
    extreme energies to the big stages of the world.`,
    p1: `Redmo is a prominent Dj & Producer that has grown throughout the Israeli
    nightlife, With extreme energy and style you have never seen before.`,
    p2: `Within a few years Redmo managed to play at the most of the biggest and
    popular clubs and parties at Israel such as: Forum, Selena, Shalvata,
    Madison and the Desert.`,
    p3: `In addition Redmo played at special events with
    over 30k people.`,
    p4: `It's hard to miss Redmo's unique visuals & style when
    he enter the stage.`,
    p5: `It's hard to miss Redmo's unique visuals & style In
    his sets Redmo combines original music, special edits and perform high
    level techniques.`,
    p6: `It's hard to miss Redmo's unique visuals & style Redmo
    is the next promise at the electronic dance scene, As a child who grew
    up on music and achieved great success, He is determined to bring his
    extreme energies to the big stages of the world.`,
    home: "HOME",
    presskit: `PRESSKIT`,
    contact: `CONTACT`,
    aboutredmo: "ABOUT REDMO",
    promolist: "Join my promo list!",
    join: "join me",
    download: "download",
    content: "content",
    pstudio: "Photos from the studio",
    pgigs: "Photos from gigs",
    pfestivals: "Photos from festivals",
    dvideos: "Edited videos",
    logo: "logo",
    music: "Music",
    nreleases: "Next releases",
    whatsapp: "whatsapp me !",
    redbooking: "redmo booking",
    mymusic: "MY MUSIC",
  },
  hb: {
    p1: `עילאי לויט (רדמו) הוא אחד הכשרונות הבולטים שצמחו בישראל.`,
    p2: `עם אנרגיה המטורפת, והסטייל המיוחד שהוא מביא איתו לבמה, הצליח להגיע לנגן בתוך זמן קצר במועדונים הגדולים ביותר בישראל כגון - פורום, סלינה, שלוותה, דזרט,מדיסון, ועוד. בנוסף, הוא גם ניגן באירועי ענק של למעלה מ30 אלף איש!`,
    p3: `אי אפשר להתעלם מהויזואליות הייחודית של רדמו וכמובן מהסטייל שהוא מביא איתו לבמה.`,
    p4: `רדמו משלב בסטים שלו מוזיקה מקורית, עריכות ייחודיות, ויכולות טכניות גבוהות.`,
    p5: `רדמו הוא ההבטחה הבאה בעולם המוזיקה האלקטרונית, כילד שגדל על מוזיקה והגיע להישגים הוא נחוש להמשיך להביא את האנרגיה שלו לבמות ענק ברחבי העולם`,
    p6: ` `,
    info: `עילאי לויט (רדמו) הוא אחד הכשרונות הבולטים שצמחו בישראל. עם אנרגיה המטורפת, והסטייל המיוחד שהוא מביא איתו לבמה, הצליח להגיע לנגן בתוך זמן קצר במועדונים הגדולים ביותר בישראל כגון - פורום, סלינה, שלוותה, דזרט,מדיסון, ועוד. בנוסף, הוא גם ניגן באירועי ענק של למעלה מ30 אלף איש! אי אפשר להתעלם מהויזואליות הייחודית של רדמו וכמובן מהסטייל שהוא מביא איתו לבמה. רדמו משלב בסטים שלו מוזיקה מקורית, עריכות ייחודיות, ויכולות טכניות גבוהות. 
    רדמו הוא ההבטחה הבאה בעולם המוזיקה האלקטרונית, כילד שגדל על מוזיקה והגיע להישגים הוא נחוש להמשיך להביא את האנרגיה שלו לבמות ענק ברחבי העולם`,
    home: `דף הבית`,
    presskit: `תמונות`,
    contact: `צור קשר`,
    aboutredmo: `אודות`,
    promolist: "הצטרפו לרשימת התפוצה שלי",
    join: "הצטרפו אלי",
    download: "הורדה",
    content: "תוכן",
    pstudio: `תמונות מהסטודיו`,
    pgigs: `תמונות מהופעות`,
    pfestivals: `תמונות מפסטיבלים`,
    dvideos: `סרטונים ערוכים`,
    logo: "לוגו",
    music: `מוזיקה`,
    nreleases: `שיחרורים קרובים`,
    whatsapp: "שלחו לי וואסטאפ",
    redbooking: "הופעות רדמו",
    mymusic: "המוזיקה שלי",
  },
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "hb", // set fallback locale
  messages, // set locale messages
});

export default i18n;
