<template>
  <div id="app" :class="rtlClass">
    <div class="bgc-red-color">
      <navbar :changeRtl="changeRtl" />
      <div class="main-layout page-setting">
        <router-view />
      </div>
      <site-footer />
    </div>
  </div>
</template>
<script>
import navbar from "./cmps/navbar.vue";
import homePage from "./pages/home-page.vue";
import presskit from "./pages/presskit-page.vue";
import siteFooter from "./cmps/site-footer.vue";

export default {
  name: "App",
  components: {
    navbar,
    homePage,
    siteFooter,
    presskit,
  },
  data() {
    return { rtlClass: "ltrStatus" };
  },
  methods: {
    changeRtl(locale) {
      locale == "hb"
        ? (this.rtlClass = "rtlStatus")
        : (this.rtlClass = "ltrStatus");
    },
  },
  mounted() {
    let accessability = document.createElement("script");
    accessability.setAttribute(
      "src",
      "https://cdn.enable.co.il/licenses/enable-L10943xt34qa8mrz-0222-26916/init.js"
    );
    document.body.appendChild(accessability);
  },
};
</script>
