<template>
  <div class="navbar-container">
    <div class="toggle">
      <button
        v-for="entry in languages"
        :key="entry.title"
        @click="changeLocale(entry.language)"
      >
        <flag :iso="entry.flag" v-bind:squared="false" />
      </button>
    </div>
    <div class="main-layout">
      <div class="navbar">
        <div class="logo">
          <img src="@/assets/imgs/RedmoWHITECrop.png" />
        </div>
        <div class="hamburger-menu" @click="openMenu()">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <nav-links ref="navbarMenu" @closeMenu="closeMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import navLinks from "./nav-links.vue";
export default {
  props: {
    changeRtl: { type: Function },
  },
  components: {
    navLinks,
  },
  data() {
    return {
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "il", language: "hb", title: "עברית" },
      ],
    };
  },
  methods: {
    openMenu() {
      this.$refs.navbarMenu.$el.classList.add("open-menu");
    },
    closeMenu() {
      this.$refs.navbarMenu.$el.classList.remove("open-menu");
    },
    changeLocale(locale) {
      i18n.locale = locale;
      this.changeRtl(locale);
    },
  },
};
</script>
