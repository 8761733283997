<template>
  <div class="main-contact">
    <div class="contact-img">
      <img src="../assets/imgs/contact-img.jpg" alt="" />
    </div>
    <div class="contact-info">
      <h2>{{ $t("contact") }}</h2>
      <div class="contact-details">
        <p>
          <span>{{ $t("redbooking") }}</span>
        </p>
        <p>
          <i>📩</i>
          <a href="mailto:djredmo@gmail.com"> djredmo@gmail.com</a>
        </p>
        <a href="tel:050-9776622">
          <p><i>☎️</i> (+972)050-9776622</p></a
        >
        <p>
          <i>
            <a
              href="https://wa.me/972509776622?text=hello redmo!"
              target="_blank"
            >
              <img src="../assets/imgs/brands/whatsapp.png" />{{
                $t("whatsapp")
              }}
            </a>
          </i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //test again
  components: {},
};
</script>
