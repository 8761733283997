<template>
  <div class="about-main main-layout">
    <div class="about-me-title">
      <h2>{{ $t("aboutredmo") }}</h2>
      <img src="../assets/imgs/RedmoNew.jpg" />
      <div class="presskit-download">
        <a href="https://linktr.ee/Djredmo" target="_blank">
          {{ $t("mymusic") }}
        </a>
      </div>
    </div>
    <div class="about-me-text-area">
      <p>
        {{ $t("p1") }}
      </p>
      <p>
        {{ $t("p2") }}
      </p>
      <p>
        {{ $t("p3") }}
      </p>
      <p>
        {{ $t("p4") }}
      </p>
      <p>
        {{ $t("p5") }}
      </p>
      <p>
        {{ $t("p6") }}
      </p>
      <div class="presskit-download">
        <a href="https://linktr.ee/Djredmo" target="_blank">
          {{ $t("mymusic") }}
        </a>
      </div>
      <squarePics />
    </div>
  </div>
</template>
<script>
import squarePics from "../cmps/square-pics.vue";

export default {
  components: {
    squarePics,
  },
};
</script>
