<template>
    <div class="square-pics">
        <div><img src="../assets/imgs/bgp.jpg" alt="" /></div>
        <div><img src="../assets/imgs/beraland.jpeg" alt="" /></div>
        <div><img src="../assets/imgs/hangar.jpeg" alt="" /></div>
        <div><img src="../assets/imgs/medi.jpeg" alt="" /></div>
    </div>
</template>

<script>
    export default {};
</script>
