import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/style/main.css";
import i18n from "@/plugins/i18n";
import FlagIcon from "vue-flag-icon";
import { initializeApp } from "firebase/app";

Vue.use(FlagIcon);
Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyBTjR-idd2Kyx2Hb6xKxEnDsiBfLI0IWsQ",
  authDomain: "djredmo-ba2a0.firebaseapp.com",
  projectId: "djredmo-ba2a0",
  storageBucket: "djredmo-ba2a0.appspot.com",
  messagingSenderId: "995396179898",
  appId: "1:995396179898:web:b371438c0741cdc212ef83",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
